/**
 * Checkout Methods exhibition sequence
 */
const sequence = {
  ame: 10,
  picpay: 20,
  credit: 30,
  pix: 40,
  boleto: 50,
  paypal: 70,
  transfer: 60,
}

export default sequence
